import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import Copy from 'components/category/copy'
import Content from 'components/category/content'
import AdditionalResources from 'components/new/additional-resources'
import Videos from 'components/category/videos'
import Layout from 'components/layout'
import { graphql, Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const items = [
  {
    link: '/lawn-garden/residential-zero-turns/z300-series/',
    imageId: 'z300Image',
    name: 'Z300 Series',
    alt: 'John Deere Z300 Series Residential ZTrak Mowers',
  },
  {
    link: '/lawn-garden/residential-zero-turns/z500-series/',
    imageId: 'z500Image',
    name: 'Z500 Series',
    alt: 'John Deere Z500 Series Residential ZTrak Mowers',
  },
  {
    link: '/lawn-garden/residential-zero-turns/z700-series/',
    imageId: 'z700Image',
    name: 'Z700 Series',
    alt: 'John Deere Z700 Series Residential ZTrak Mowers',
  },
]

const ResidentialZeroTurnsPage = ({ data }) => {
  const { allHutsonPromotion, allDeereOffer, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  if (allDeereOffer) {
    allDeereOffer.nodes.forEach(node => promos.push(node))
  }
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>John Deere Residential Zero-Turn Mowers | Hutson Inc</title>
        <meta
          name='description'
          content='Hutson has a full line-up of John Deere residential zero-turn mowers, including the Z300, Z500, and Z700 series. Find the right zero-turn for any size lawn.'
        />
        <meta name='keywords' content='residential mowers, zero turns, ztrak, z300, z500, z700' />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Lawn & Garden Equipment',
                'item': 'https://www.hutsoninc.com/lawn-garden/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Residential Zero-Turn Mowers',
                'item': 'https://www.hutsoninc.com/lawn-garden/residential-zero-turns/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Residential Zero-Turn Mowers' />

      <Content>
        <Floater items={gridItems} />

        <Copy header='John Deere Residential Zero-Turn Mowers'>
          <p>
            Hutson, your local John Deere dealer, offers a full line up of John Deere Zero Turn Lawn
            Mowers. Starting as small as 42-inch decks up to 62-inches. The&nbsp;
            <Link to='/lawn-garden/residential-zero-turns/z300-series/'>
              John Deere z300 Series
            </Link>
            &nbsp;is great for those with a smaller yard but loves the time savings that a zero turn
            brings to the table. Need to step it up a bit? You might consider moving to the&nbsp;
            <Link to='/lawn-garden/residential-zero-turns/z500-series/'>
              John Deere z500 Series
            </Link>
            &nbsp;or&nbsp;
            <Link to='/lawn-garden/residential-zero-turns/z700-series/'>
              John Deere z700 Series
            </Link>
            &nbsp;mowers. If you are looking for a time saving mower, then you are in the right
            place.
          </p>
        </Copy>

        <Promos data={promos} type='Residential Zero-Turn Mower' />

        <Videos
          type='Residential Zero-Turn Mower'
          id='mK7m73iWk9M'
          list='PLUz5fj7f_mw9G8HvfiTuh3yZFOh-Ctfby'
        />
      </Content>
      <AdditionalResources
        resources={[
          {
            title: 'Product Brochures',
            links: [
              <OutboundLink href='https://www.deere.com/assets/publications/index.html?id=a052c0b8'>
                View Residential Zero-Turn Mowers Brochure
              </OutboundLink>,
            ],
          },
          {
            title: 'Offers & Discounts',
            links: [
              <Link to='/promotions/john-deere/residential-zero-turns/'>
                View Current Promotions
              </Link>,
            ],
          },
        ]}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query residentialZeroTurnsSubcategoryQuery($subcategory: String = "residential-zero-turns") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $subcategory }, type: { eq: "john-deere" } } }
      }
      sort: { fields: [endDate], order: DESC }
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    allDeereOffer(filter: { subcategoryList: { in: [$subcategory] } }) {
      nodes {
        ...DeerePromo
      }
    }
    heroImage: file(relativePath: { eq: "headers/residential-zero-turns-subcategory-header.jpg" }) {
      ...FullWidthImage
    }
    z300Image: file(relativePath: { eq: "lawn-and-garden/z300-series.jpg" }) {
      ...FloatingGridImage
    }
    z500Image: file(relativePath: { eq: "lawn-and-garden/z500-series.jpg" }) {
      ...FloatingGridImage
    }
    z700Image: file(relativePath: { eq: "lawn-and-garden/z700-series.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default ResidentialZeroTurnsPage
